import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, CardBody, Row, Col, CardTitle, FormGroup, Spinner } from "reactstrap";

import "../DashboardFilter.scss";

import OptionFilter from "../OptionFilter";
import RangeFilter from "../RangeFilter";
import SelectFilter from "../SelectFilter";
import {
    GenderType,
    PlanCategory,
    DashboardUserFilterTypes,
    DeleteAnalyticsConstants
} from "../../../constants";
import { SplineChart } from "../../../components/Chart";
import { FilterButton } from "../../../components/FilterButton";
import { DropdownButton } from "../../../components/DropdownButton";
import { RangePicker } from "../../../components/RangePicker";
import {
    getCountryListing,
    getDeletedAnalytics,
    downloadCSV,
    downloadPDF
} from "../../../store/actions";
import { Checkbox } from "../../../components/Checkbox";
import { SearchableSelect } from "../../../components/InputSelect";
import { useDispatch } from "react-redux";
import { formatGraphLabels } from "../../../utils/commonUtils";
import { dateTimeFormat } from "../../../utils/dateFormatUtils";
import { downloadCSVData, downloadPDFData } from "../../../utils/downloadUtil";

const USER_FILTERS = {
    USERS: { label: "Users", apiKey: "UserType" },
    DELETED_REASONS: { label: "Deleted Reasons", apiKey: "DeleteReason" },
    GENDER: { label: "Gender", apiKey: "Gender" },
    AGE: { label: "Age", apiKey: "Age" },
    LOCATION: { label: "Location", apiKey: "Location" }
    // PAYMENT_PLAN: { label: "Payment Plan", apiKey: "PaymentPlan" }
};

const excludeFilters = [
    DashboardUserFilterTypes.GUARDIAN,
    DashboardUserFilterTypes.TOTAL_ACTIVE_USERS,
    DashboardUserFilterTypes.ALL_USERS
];

const deletedByExcludeFilters = [
    DeleteAnalyticsConstants.FOUND_FUTURE_PARTNER,
    DeleteAnalyticsConstants.MATCH_NOT_FOUND,
    DeleteAnalyticsConstants.EXPECTING_DIFFERENT,
    DeleteAnalyticsConstants.UNLIKE_PLATFORM,
    DeleteAnalyticsConstants.OTHER,
    DeleteAnalyticsConstants.NONE
];

const deletedReasonExcludeFilters = [
    DeleteAnalyticsConstants.ADMIN_DELETED,
    DeleteAnalyticsConstants.USER_DELETED
];

const DashboardDeletedFilter = (props) => {
    const {
        filters,
        commonFilters,
        isFilterOpen,
        setIsFilterOpen,
        prepareUserFilterValues,
        ageRange
    } = props;
    const [graphData, setGraphData] = useState(null);
    const [comparedGraphData, setComparedGraphData] = useState(null);
    const [enableCompare, setEnableCompare] = useState(false);
    const [selectedFilterToCompare, setSelectedFilterToCompare] = useState(null);
    const [comparedFilterValue, setComparedFilterValue] = useState(null);
    const [toggleOpen, setToggleOpen] = useState(false);
    const [deletedFilterType, setDeletedFilterType] = useState({
        type: null
    });
    const [deletedFilterReason, setDeletedFilterReason] = useState(null);

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        comparedStartDate: null,
        comparedEndDate: null
    });

    let filterOne = useMemo(() => {
        return {
            UserType: filters.user,
            ...(filters.gender && { Gender: filters.gender }),
            ...(filters.maxAge &&
                filters.minAge && { StartAge: filters.minAge, EndAge: filters.maxAge }),
            ...(Object.keys(filters?.location).length && {
                Location: filters?.location?.value?.Id
            }),
            ...(filters.plan && { PaymentPlan: filters.plan }),
            ...((deletedFilterReason || deletedFilterType?.type) && {
                DeleteReason: deletedFilterReason ? deletedFilterReason : deletedFilterType?.type
            }),
            ...(dateRange.startDate &&
                dateRange.endDate && {
                    DateRange: {
                        StartDate: dateRange.startDate.format(dateTimeFormat.apiDateFormat),
                        EndDate: dateRange.endDate.format(dateTimeFormat.apiDateFormat)
                    }
                })
        };
    }, [filters, deletedFilterReason, deletedFilterType, dateRange]);

    let filterTwo = useMemo(() => {
        let comparedValue =
            typeof comparedFilterValue === "object"
                ? comparedFilterValue?.value?.Id
                : comparedFilterValue;
        return {
            ...filterOne,
            ...(comparedFilterValue &&
                selectedFilterToCompare?.value?.apiKey !== USER_FILTERS.AGE.apiKey && {
                    [selectedFilterToCompare?.value?.apiKey]: comparedValue
                }),
            ...(selectedFilterToCompare?.value?.apiKey === USER_FILTERS.AGE.apiKey && {
                StartAge: comparedFilterValue?.minAge,
                EndAge: comparedFilterValue?.maxAge
            }),
            ...(dateRange.comparedStartDate &&
                dateRange.comparedEndDate && {
                    DateRange: {
                        StartDate: dateRange.comparedStartDate.format(dateTimeFormat.apiDateFormat),
                        EndDate: dateRange.comparedEndDate.format(dateTimeFormat.apiDateFormat)
                    }
                })
        };
    }, [filterOne, comparedFilterValue, dateRange]);

    const dispatch = useDispatch();
    const userAnalyticsAbortController = useRef();

    useEffect(() => {
        userAnalyticsAbortController.current = new AbortController();

        dispatch(
            getDeletedAnalytics(
                {
                    Filter1: filterOne,
                    ...(((enableCompare && comparedFilterValue) ||
                        (dateRange.comparedStartDate && dateRange.comparedEndDate)) && {
                        Filter2: filterTwo
                    })
                },
                userAnalyticsAbortController.current.signal
            )
        )
            .then((res) => {
                if (res && res.DeletedUserAnalytics1) {
                    setGraphData({
                        data: Object.values(res.DeletedUserAnalytics1).map(
                            (data) => data.TotalUsers
                        ),
                        categories: formatGraphLabels(
                            Object.keys(res.DeletedUserAnalytics1),
                            res.Format
                        )
                    });
                }

                if (res && res.DeletedUserAnalytics2) {
                    setComparedGraphData({
                        data: Object.values(res.DeletedUserAnalytics2).map(
                            (data) => data.TotalUsers
                        ),
                        categories: formatGraphLabels(
                            Object.keys(res.DeletedUserAnalytics2),
                            res.Format
                        )
                    });
                } else {
                    setComparedGraphData(null);
                }
            })
            .catch((err) => {});

        return () => {
            if (userAnalyticsAbortController.current) {
                userAnalyticsAbortController.current.abort();
            }
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dateRange, comparedFilterValue, filterOne, filterTwo]);

    const handleMenuToggle = (e) => {
        setToggleOpen((prevOpen) => !prevOpen);
    };

    const handleFilterOpenClose = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const getFilterOptions = () => {
        return Object.values(USER_FILTERS).map((item) => ({
            label: item.label,
            value: item
        }));
    };

    const handleCompareToggle = () => {
        if (enableCompare) {
            setSelectedFilterToCompare(null);
            setComparedFilterValue(null);
        } else {
            if (
                dateRange.startDate ||
                dateRange.endDate ||
                dateRange.comparedStartDate ||
                dateRange.comparedEndDate
            ) {
                setDateRange({
                    startDate: null,
                    endDate: null,
                    comparedStartDate: null,
                    comparedEndDate: null
                });
            }
        }
        setEnableCompare((prev) => !prev);
    };

    const handleTimeRangeApplied = (val) => {
        if (enableCompare) {
            setSelectedFilterToCompare(null);
            setComparedFilterValue(null);
            setEnableCompare(false);
        }
        setDateRange(val);
    };

    const renderDate = () => {
        if (dateRange.startDate && dateRange.endDate) {
            return (
                "From " +
                dateRange.startDate.format("YYYY") +
                " Till " +
                dateRange.endDate.format("YYYY")
            );
        } else {
            return "From " + new Date().getFullYear() + " Till " + new Date().getFullYear();
        }
    };

    const getCategories = () => {
        if (graphData?.categories && comparedGraphData?.categories) {
            return [...graphData?.categories, ...comparedGraphData?.categories];
        }
        return graphData?.categories;
    };

    const onComparedGenderSelect = (value) => {
        setComparedFilterValue(value);
    };

    const onComparedAgeSelect = (minVal, maxVal) => {
        setComparedFilterValue({ minAge: minVal, maxAge: maxVal });
    };

    const handleComparedPayment = (value) => {
        setComparedFilterValue(value);
    };

    const getLocationOptions = (data) => {
        return data.map((item) => ({
            label: item.Name,
            value: item
        }));
    };

    const renderUserFilter = () => {
        return (
            <Col md="2">
                <OptionFilter
                    title="Select User"
                    isOpen={true}
                    excludeFilters={excludeFilters}
                    filterConstants={DashboardUserFilterTypes}
                    selectedFilterValue={comparedFilterValue}
                    onChange={(val) => setComparedFilterValue(val)}
                    filterValues={prepareUserFilterValues()}
                    required={true}
                />
            </Col>
        );
    };

    const renderGenderFilter = () => {
        return (
            <Col md="2">
                <OptionFilter
                    title="Select Gender"
                    isOpen={true}
                    filterConstants={GenderType}
                    selectedFilterValue={comparedFilterValue}
                    onChange={onComparedGenderSelect}
                />
            </Col>
        );
    };

    const renderAgeFilter = () => {
        return (
            <Col md="2">
                <RangeFilter
                    title="Select Age"
                    min={ageRange?.MinimumAge}
                    max={ageRange?.MaximumAge}
                    minValue={comparedFilterValue?.minAge}
                    maxValue={comparedFilterValue?.maxAge}
                    isOpen={true}
                    onChange={onComparedAgeSelect}
                />
            </Col>
        );
    };

    const renderLocationFilter = () => {
        return (
            <Col md="2">
                <SelectFilter
                    title="Select Location"
                    requestAction={getCountryListing}
                    isOpen={true}
                    placeholder="Select Location"
                    filterValue={comparedFilterValue}
                    onChange={(val) => setComparedFilterValue(val || [])}
                    getOptions={getLocationOptions}
                />
            </Col>
        );
    };

    const renderPaymentPlanFilter = () => {
        return (
            <Col md="2">
                <OptionFilter
                    title="Select Payment Plan"
                    isOpen={true}
                    filterConstants={PlanCategory}
                    selectedFilterValue={comparedFilterValue}
                    onChange={handleComparedPayment}
                />
            </Col>
        );
    };

    const handleCSVExport = () => {
        setToggleOpen(false);

        dispatch(
            downloadCSV("/analytics/deleted-users/csv", {
                Filter1: filterOne,
                ...(((enableCompare && comparedFilterValue) ||
                    (dateRange.comparedStartDate && dateRange.comparedEndDate)) && {
                    Filter2: filterTwo
                })
            })
        )
            .then((res) => {
                downloadCSVData(res);
            })
            .catch((err) => {});
    };

    const handlePDFExport = () => {
        setToggleOpen(false);

        dispatch(
            downloadPDF("/analytics/deleted-users/pdf", {
                Filter1: filterOne,
                ...(((enableCompare && comparedFilterValue) ||
                    (dateRange.comparedStartDate && dateRange.comparedEndDate)) && {
                    Filter2: filterTwo
                })
            })
        )
            .then((res) => {
                downloadPDFData(res);
            })
            .catch((err) => {});
    };

    const renderComparedFilters = () => {
        let comparedFilter = null;

        switch (selectedFilterToCompare?.label) {
            case USER_FILTERS.USERS.label:
                comparedFilter = renderUserFilter();
                break;
            case USER_FILTERS.GENDER.label:
                comparedFilter = renderGenderFilter();
                break;
            case USER_FILTERS.AGE.label:
                comparedFilter = renderAgeFilter();
                break;
            case USER_FILTERS.LOCATION.label:
                comparedFilter = renderLocationFilter();
                break;

            case USER_FILTERS.DELETED_REASONS.label:
                comparedFilter = renderDeletedReasonFilter(true);
                break;

            // case USER_FILTERS.PAYMENT_PLAN.label:
            //     comparedFilter = renderPaymentPlanFilter();
            //     break;
            default:
                comparedFilter = null;
                break;
        }
        return comparedFilter;
    };

    const handleDeleteFilterChange = (isComparing = false, filter, value) => {
        if (isComparing) {
            setComparedFilterValue(value);
            return;
        }

        if (value === DeleteAnalyticsConstants.ADMIN_DELETED) {
            setDeletedFilterReason(null);
        }
        setDeletedFilterType((prev) => ({
            ...prev,
            [filter]: value
        }));
    };

    const handleReasonFilterChange = (isComparing = false, value) => {
        if (isComparing) {
            setComparedFilterValue(value);
            return;
        }

        setDeletedFilterReason(value);
    };

    const renderDeletedByFilter = (isComparing = false) => {
        return (
            <Col md="1">
                <OptionFilter
                    title={isComparing ? "Select Deleted By" : "Deleted By"}
                    isOpen={isComparing ? true : isFilterOpen}
                    filterConstants={DeleteAnalyticsConstants}
                    excludeFilters={deletedByExcludeFilters}
                    selectedFilterValue={isComparing ? comparedFilterValue : deletedFilterType.type}
                    onChange={(val) => handleDeleteFilterChange(isComparing, "type", val)}
                />
            </Col>
        );
    };

    const renderDeletedReasonFilter = (isComparing = false) => {
        return (
            <Col md="2">
                <OptionFilter
                    title={isComparing ? "Select Deleted Reason" : "On Reason"}
                    isOpen={isComparing ? true : isFilterOpen}
                    filterConstants={DeleteAnalyticsConstants}
                    excludeFilters={isComparing ? [] : deletedReasonExcludeFilters}
                    selectedFilterValue={isComparing ? comparedFilterValue : deletedFilterReason}
                    onChange={(val) => handleReasonFilterChange(isComparing, val)}
                />
            </Col>
        );
    };

    return (
        <div>
            <Row className="pb-2">
                <Col md="2">
                    <div className="d-flex align-items-center">
                        <FilterButton text="Filter" onClick={handleFilterOpenClose} />
                        <i
                            onClick={handleFilterOpenClose}
                            className={`cursor-pointer bx bx-chevron-${
                                isFilterOpen ? "down" : "right"
                            } chevron-icon`}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="pb-4">
                {commonFilters}
                {isFilterOpen && renderDeletedByFilter()}
                {isFilterOpen &&
                    deletedFilterType.type === DeleteAnalyticsConstants.USER_DELETED &&
                    renderDeletedReasonFilter()}
            </Row>
            <Row className="px-3">
                <FormGroup className="my-2">
                    <Checkbox
                        title="Compare with:"
                        checked={enableCompare}
                        onChange={handleCompareToggle}
                        id="enable-copare"
                    />
                </FormGroup>
                {enableCompare && (
                    <Col md="2">
                        <SearchableSelect
                            options={getFilterOptions()}
                            placeholder="Select Filter"
                            isClearable
                            value={selectedFilterToCompare}
                            onChange={(val) => {
                                setComparedFilterValue(null);
                                setSelectedFilterToCompare(val);
                            }}
                        />
                    </Col>
                )}
                {renderComparedFilters()}
            </Row>
            <div className="filter-main-container" />
            <Row>
                <Col md="12">
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <p>{renderDate()}</p>
                        <div className="d-flex">
                            <RangePicker value={dateRange} onChange={handleTimeRangeApplied} />
                            {/* <FilterButton
                                onClick={handleExportClick}
                                text="Export"
                                classes="font-weight-bold"
                            /> */}

                            <DropdownButton
                                handlePDF={handlePDFExport}
                                handleCSV={handleCSVExport}
                                handleToggle={handleMenuToggle}
                                toggleOpen={toggleOpen}
                            />
                        </div>
                    </div>
                </Col>
                <Col md="12">
                    <Card className="mt-3">
                        <CardBody>
                            <CardTitle className="mb-4"> Deleted Users </CardTitle>
                            {graphData ? (
                                <SplineChart
                                    graph1Title="Deleted Users"
                                    graph2Title="Compared Deleted Users"
                                    data={graphData?.data}
                                    data2={comparedGraphData?.data}
                                    categories={getCategories()}
                                />
                            ) : (
                                <div className="d-flex align-items-center justify-content-center dashboard-graph-height">
                                    <Spinner size="md" color="primary" />
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DashboardDeletedFilter;
