import React from "react";

import "./DataTable.scss";
import { SortOrder } from "../../constants";
import FilterCell from "./FilterCell";
import MultiFilterBox from "./MultiFilterBox";

const TableHead = (props) => {
    const { options, currentSortOrder, currentSortColumn, onSortClick } = props;

    const handleTableHeadClick = () => {
        if (options.isSortable && onSortClick && typeof onSortClick === "function") {
            onSortClick(options);
        }

        if (options.onFilterClick && typeof options.onFilterClick === "function") {
            options.onFilterClick();
        }
    };

    const renderSortIcon = () => {
        if (!currentSortColumn || currentSortColumn !== options.sortColumn) {
            return <i className="bx bx-sort"></i>;
        }
        if (options.showAlphabetIcon) {
            return currentSortOrder === SortOrder.ASC ? (
                <i className="fas fa-sort-alpha-down"></i>
            ) : (
                <i className="fas fa-sort-alpha-up-alt"></i>
            );
        }
        return currentSortOrder === SortOrder.ASC ? (
            <i className="fas fa-sort-amount-down-alt"></i>
        ) : (
            <i className="fas fa-sort-amount-up"></i>
        );
    };

    return (
        <th>
            {!options.isFilterable && !options.isMultiFilter ? (
                <div
                    className={`data-table-head ${
                        options.isSortable ||
                        (options.onFilterClick && typeof options.onFilterClick === "function")
                            ? "sortable"
                            : ""
                    }`}
                    onClick={handleTableHeadClick}
                >
                    {options.title}
                    {options.onFilterClick && typeof options.onFilterClick === "function" && (
                        <div className="cursor-pointer mt-1">
                            <i className="bx bx-filter-alt"></i>
                        </div>
                    )}
                    {options.isSortable && renderSortIcon()}
                </div>
            ) : options.isMultiFilter ? (
                <MultiFilterBox options={options} />
            ) : (
                <FilterCell options={options} />
            )}
        </th>
    );
};

export default TableHead;
