import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { DataTable } from "../../components/DataTable";
import { Pagination } from "../../components/Pagination";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { CustomBadge } from "../../components/Badge";
import { getTransactionListing } from "../../store/actions";
import {
    SortOrder,
    PlatformConstants,
    PlanCategory,
    TransactionTypes,
    CountryConstants
} from "../../constants";
import { dateTimeFormat, convertDateTime } from "../../utils/dateFormatUtils";
import { getFormattedAmountWithCurrency } from "../../utils/commonUtils";

const TRANSACTIONS_COLUMNS = {
    CURRENCY: "Currency",
    COUNTRY: "Location",
    FEE: "Fee",
    TYPE: "Type",
    DATE: "Date",
    GROSS_AMOUNT: "Gross Amount",
    NET_AMOUNT: "Net Amount",
    PACKAGE: "Package",
    PLATFORM: "Platform"
};

const SORTABLE_COLUMNS = {
    [TRANSACTIONS_COLUMNS.DATE]: "Date",
    [TRANSACTIONS_COLUMNS.PACKAGE]: "Package",
    [TRANSACTIONS_COLUMNS.PLATFORM]: "Platform",
    [TRANSACTIONS_COLUMNS.NET_AMOUNT]: "NetAmount",
    [TRANSACTIONS_COLUMNS.GROSS_AMOUNT]: "GrossAmount",
    [TRANSACTIONS_COLUMNS.FEE]: "Fee",
    [TRANSACTIONS_COLUMNS.COUNTRY]: "Country"
};

const TransactionsListing = (props) => {
    const { getTransactionListing, data, fetching, total } = props;

    const transactionListAbortController = useRef(null);
    const [page, setPage] = useState(1);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [filters, setFilters] = useState({
        type: null,
        platform: null,
        location: null
    });

    useEffect(() => {
        transactionListAbortController.current = new AbortController();

        getTransactionListing(
            {
                Page: page,
                ...(currentSortColumn &&
                    currentSortOrder && {
                        Column: currentSortColumn,
                        Direction: currentSortOrder
                    }),
                ...(filters.type && { Type: filters.type }),
                ...(filters.platform && { Platform: filters.platform }),
                ...(filters.location && { Location: filters.location })
            },
            transactionListAbortController.current.signal
        )
            .then((res) => {})
            .catch((err) => {});

        return () => {
            if (transactionListAbortController.current.signal) {
                transactionListAbortController.current.abort();
            }
        };

        //es-lint-disable-next-line react-hooks/exhaustive-deps
    }, [page, currentSortColumn, currentSortOrder, filters, getTransactionListing]);

    const handleSortClick = (data) => {
        let sortOrder;
        if (!currentSortOrder || currentSortOrder === SortOrder.DESC) {
            sortOrder = SortOrder.ASC;
        } else {
            sortOrder = SortOrder.DESC;
        }
        setCurrentSortColumn(SORTABLE_COLUMNS[data.title]);
        setCurrentSortOrder(sortOrder);
    };

    const handlePageClick = (pageNo) => {
        setPage(pageNo);
    };

    const handleFilterChange = (filter, value) => {
        setPage(1);
        setFilters((prev) => ({
            ...prev,
            [filter]: value
        }));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Transactions"} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <DataTable
                                        data={data}
                                        loading={fetching}
                                        currentSortOrder={currentSortOrder}
                                        currentSortColumn={currentSortColumn}
                                        onSortClick={handleSortClick}
                                        config={[
                                            {
                                                title: TRANSACTIONS_COLUMNS.DATE,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[TRANSACTIONS_COLUMNS.DATE],
                                                isSortable: true,
                                                render: (item) =>
                                                    convertDateTime({
                                                        date: item?.Date,
                                                        customFormat: dateTimeFormat.appDateFormat
                                                    }) || "-"
                                            },
                                            {
                                                title: TRANSACTIONS_COLUMNS.TYPE,
                                                isFilterable: true,
                                                filterConstants: TransactionTypes,
                                                filterValue: filters.type,
                                                onFilterSelect: (value) =>
                                                    handleFilterChange("type", value),
                                                render: (item) => (
                                                    <CustomBadge
                                                        color={TransactionTypes.getLabelClass(
                                                            item?.Type
                                                        )}
                                                        classes={TransactionTypes.getLabelClass(
                                                            item?.Type
                                                        )}
                                                    >
                                                        {TransactionTypes.getDisplayTextKey(
                                                            item?.Type
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: TRANSACTIONS_COLUMNS.CURRENCY,
                                                render: (item) => item?.Currency || "-"
                                            },
                                            {
                                                title: TRANSACTIONS_COLUMNS.PLATFORM,
                                                isFilterable: true,
                                                filterConstants: PlatformConstants,
                                                filterValue: filters.platform,
                                                onFilterSelect: (value) =>
                                                    handleFilterChange("platform", value),
                                                render: (item) => (
                                                    <CustomBadge
                                                        color={PlatformConstants.getLabelClass(
                                                            item?.Platform
                                                        )}
                                                        classes={PlatformConstants.getLabelClass(
                                                            item?.Platform
                                                        )}
                                                    >
                                                        {PlatformConstants.getDisplayTextKey(
                                                            item?.Platform
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: TRANSACTIONS_COLUMNS.PACKAGE,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[TRANSACTIONS_COLUMNS.PACKAGE],
                                                isSortable: true,
                                                showAlphabetIcon: true,
                                                render: (item) => (
                                                    <CustomBadge
                                                        color={PlanCategory.getLabelClass(
                                                            item?.Package
                                                        )}
                                                        classes={PlanCategory.getLabelClass(
                                                            item?.Package
                                                        )}
                                                    >
                                                        {PlanCategory.getDisplayTextKey(
                                                            item?.Package
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: TRANSACTIONS_COLUMNS.FEE,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[TRANSACTIONS_COLUMNS.FEE],
                                                isSortable: true,
                                                render: (item) =>
                                                    getFormattedAmountWithCurrency(item?.Fee) || "-"
                                            },

                                            {
                                                title: TRANSACTIONS_COLUMNS.GROSS_AMOUNT,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[
                                                        TRANSACTIONS_COLUMNS.GROSS_AMOUNT
                                                    ],
                                                isSortable: true,
                                                render: (item) =>
                                                    getFormattedAmountWithCurrency(
                                                        item?.GrossAmount
                                                    ) || "-"
                                            },
                                            {
                                                title: TRANSACTIONS_COLUMNS.NET_AMOUNT,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[
                                                        TRANSACTIONS_COLUMNS.NET_AMOUNT
                                                    ],
                                                isSortable: true,
                                                render: (item) =>
                                                    getFormattedAmountWithCurrency(
                                                        item?.NetAmount
                                                    ) || "-"
                                            },
                                            {
                                                title: TRANSACTIONS_COLUMNS.COUNTRY,
                                                filterConstants: CountryConstants,
                                                isFilterable: true,
                                                scrollFilter: true,
                                                filterValue: filters.location,
                                                onFilterSelect: (value) =>
                                                    handleFilterChange("location", value),
                                                render: (item) => item?.Country?.Name
                                            }
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.transactions.list,
        fetching: state.transactions.fetching,
        total: state.transactions.totalTransactions
    };
};

const mapDispatchToProps = {
    getTransactionListing
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsListing);
